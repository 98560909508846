import { DocumentNode } from "graphql";
import useSWR, { Key } from "swr";
import type { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import { client } from "~lib";
import { useContext } from "react";
import { BaseContext } from "~context";
import { BareFetcher, PublicConfiguration } from "swr/dist/types";

export const generateKey = <T extends DocumentNode>(
  query: T,
  variables?: VariablesOf<T>
): Key => {
  return query ? JSON.stringify([query, variables ?? {}]) : null;
};

export const useQuery = <T extends DocumentNode>(
  query: T,
  variables?: VariablesOf<T>,
  options?: Partial<
    PublicConfiguration<ResultOf<T>, Error, BareFetcher<ResultOf<T>>>
  >
) => {
  const { organization } = useContext(BaseContext);

  const queryFn = options?.fetcher
    ? (options.fetcher.bind(null, variables) as BareFetcher<ResultOf<T>>)
    : async () => client(organization?.id).request(query, variables);

  const key = generateKey(query, variables);
  const results = useSWR<ResultOf<T>, Error>(key, queryFn, options);

  const isLoading = typeof results.data === "undefined" && !results.error;

  return { ...results, key, isLoading };
};
