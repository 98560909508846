import { Text } from "flicket-ui";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useWillShowPreviewCompetition } from "~features/events/hooks/usePreviewCompetition";

import { EventAccessStatus } from "~graphql/sdk";
import { useAccount } from "~hooks";
import { useGetEventWithAccessControl } from "~hooks/useEventAccessControl";

const BANNER_HEIGHT = 36;

export default function HeaderAlertBanner() {
  const {
    isBannerVisible,
    showAdminPreviewBanner,
    showBackOfficeOrderBanner,
  } = useShowHeaderBanner();

  if (!isBannerVisible) {
    return null;
  }

  const label = (() => {
    if (showAdminPreviewBanner) {
      return "Event preview";
    }

    if (showBackOfficeOrderBanner) {
      return "Back office order";
    }

    return "";
  })();

  return (
    <Text
      color="N100"
      variant="demiBold.L"
      textAlign="center"
      backgroundColor="N700"
      py={"3/4"}
      css={`
        height: ${BANNER_HEIGHT}px;
      `}
    >
      {label}
    </Text>
  );
}

export function useShowHeaderBanner() {
  const { pathname } = useRouter();
  const { accessType, isLoading } = useGetEventWithAccessControl();
  const { isAdmin, authState } = useAccount();
  const showPreviewCompetition = useWillShowPreviewCompetition();

  const showAdminPreviewBanner =
    !isLoading &&
    (accessType === EventAccessStatus.AllowedAdminPreview ||
      showPreviewCompetition);

  const showBackOfficeOrderBanner =
    authState === "authenticated" &&
    isAdmin &&
    (pathname.includes("/reservation") ||
      pathname.includes("/orders") ||
      pathname.includes("/checkout/"));

  const isBannerVisible = showAdminPreviewBanner || showBackOfficeOrderBanner;
  useEffect(() => {
    if (isBannerVisible) {
      document.body.style.paddingTop = `${BANNER_HEIGHT}px`;
    } else {
      document.body.style.paddingTop = `0px`;
    }
  }, [isBannerVisible]);

  return {
    showAdminPreviewBanner,
    showBackOfficeOrderBanner,
    isBannerVisible,
    bannerHeight: BANNER_HEIGHT,
    activeBannerHeight: isBannerVisible ? BANNER_HEIGHT : 0,
  };
}
