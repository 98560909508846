import React, { FC, ReactElement, ReactNode } from "react";
import { Flex, Container, Box, System, SystemProps, Stack } from "flicket-ui";
import { ExtendedFile } from "~graphql/sdk";
import { ContentContainer } from "./common.contentContainer";
import Image from "~components/common/Image";
import { css } from "styled-components";

type PageContainerProps = {
  title?: ReactElement;
  footer: ReactElement;
  children: ReactNode;
  bannerImage?: ExtendedFile | null;
  backgroundImage?: ExtendedFile | null;
  backgroundImageFullPage?: ExtendedFile | null;
  titleText?: string;
  containerProps?: SystemProps;
  calendarView?: boolean;
};

export const PageContainer: FC<PageContainerProps> = ({
  titleText,
  title,
  footer,
  children,
  bannerImage,
  backgroundImage,
  backgroundImageFullPage,
  containerProps,
  calendarView = false,
}) => {
  const hasFullPageBackground = !!backgroundImageFullPage;

  const Footer = React.cloneElement(footer, {
    hasFullPageBackground,
  });

  return (
    <ContentContainer
      variant="content"
      image={backgroundImage}
      imageFullPage={backgroundImageFullPage}
      innerProps={
        calendarView && {
          pt: { _: 0, md: 2 },
        }
      }
    >
      <Stack direction="vertical" pb={{ _: 2, md: 0 }} flex="1">
        {title}

        <Container
          d={{ _: "block", md: "block" }}
          innerProps={{
            position: hasFullPageBackground ? "relative" : "static",
            minHeight: { _: 0, md: "320px" },
            pt: { _: 0, md: bannerImage ? 0 : 4 },
            pb: { _: 4, md: hasFullPageBackground ? 0 : 8 },
            overflow: "hidden",
            borderRadius: { _: "none", md: "md" },
          }}
          mb={hasFullPageBackground ? 15 : 10}
          {...containerProps}
          {...(calendarView && {
            pl: 0,
            pr: 0,
            maxW: { md: "1120px" },
          })}
        >
          {bannerImage && (
            <Image
              image={bannerImage}
              alt={titleText}
              priority={true}
              // @ts-expect-error override
              m={{ _: "0 0 16px 0", md: "0 -32px 32px -32px" }}
            />
          )}

          {children}

          {hasFullPageBackground && (
            <Box
              // @ts-expect-error override
              m={{ _: 0, md: "24px -32px 0" }}
            >
              {Footer}
            </Box>
          )}
        </Container>
      </Stack>
      {!hasFullPageBackground && Footer}
    </ContentContainer>
  );
};
