import {
  ConvergeSdk,
  TrackingActivityNames,
  SdkLogEventLevel,
  CommerceActivityTrackingEventProductMetadata,
} from "@seeka-labs/converge";
import { useEffect } from "react";
import { WaitListFormValues } from "~components/events/waitlist/interface";
import {
  CurrentOrganizationOutput,
  GetOrderJobStatusQuery,
  OrderType,
} from "~graphql/typed-document-nodes";

const seekaDebug = process.env.SEEKA_DEBUG === "true";

export let converge: ConvergeSdk;

export function useSeeka(
  seekaConfig?: CurrentOrganizationOutput["seekaConfig"] | null,
  currencyCode?: string
) {
  useEffect(() => {
    initializeSeeka(seekaConfig, currencyCode);
  }, []);
}

function initializeSeeka(
  seekaConfig?: CurrentOrganizationOutput["seekaConfig"] | null,
  currencyCode?: string
) {
  if (typeof window === "undefined" || !seekaConfig) {
    return false;
  }

  try {
    converge = new ConvergeSdk({
      key: seekaConfig.seekaPublicKey,
      organisationId: seekaConfig.seekaOrganizationId,
      scopeKey: seekaConfig.seekaInstanceId,
      scopeDescription: seekaConfig.seekaScopeDescription,
      endpoint: "https://api.seeka.services",
      ingestEndpoint: "https://router.seeka.services",
      isDisabled: false,
      runtime: {},
      debug: {
        isEnabled: seekaDebug,
        minimumLevel: seekaDebug
          ? SdkLogEventLevel.Verbose
          : SdkLogEventLevel.Error,
      },
      tracking: {
        analytics: {
          autoCollection: {
            activityNames: [
              TrackingActivityNames.ViewPage,
              TrackingActivityNames.ViewProduct,
            ],
          },
        },
        defaults: { currencyCode: currencyCode },
      },
    });
    void converge?.initAsync();
  } catch (err) {
    converge = undefined;
  }

  return true;
}

export function seekaTrackCheckout(
  order: GetOrderJobStatusQuery["getOrderJobStatus"]["order"],
  currencyCode: string
) {
  if (!converge) {
    return;
  }

  if (order.orderType !== OrderType.Default) {
    return;
  }

  const groupedProducts: {
    [key: string]: CommerceActivityTrackingEventProductMetadata;
  } = {};

  order.lineItems.edges.forEach(({ node: lineItem }) => {
    const seekaProduct = {
      variantIdentifier:
        lineItem.ticketType?.id ??
        lineItem.membershipType?.id ??
        lineItem.eventAddon?.id ??
        lineItem.membershipAddon?.id,
      variantName:
        lineItem.ticketType?.name ??
        lineItem.membershipType?.membership?.name ??
        lineItem.eventAddon?.name ??
        lineItem.membershipAddon?.name,
      quantity: lineItem.quantity,
      unitPrice: lineItem.price,
      productIdentifier: order.event?.id ?? order.membership?.id,
      productName: order?.event?.title ?? order.membership?.name,
    };

    const groupingKey = `${seekaProduct.variantIdentifier}-${
      seekaProduct.variantName
    }-${seekaProduct.unitPrice}-${lineItem.seatZone ?? "empty"}`;

    // Check if a group already exists for this key
    if (!groupedProducts[groupingKey]) {
      groupedProducts[groupingKey] = { ...seekaProduct };
      return;
    }

    // Accumulate quantity for matching groups
    groupedProducts[groupingKey].quantity += seekaProduct.quantity;
  });

  void converge?.track.initiateCheckout({
    currencyCode,
    checkoutIdentifier: order?.id,
    products: Object.values(groupedProducts),
  });
}

export function seekaTrackRegistration(values: WaitListFormValues): void {
  void converge?.identity?.mergeProfile({
    email: [values.email],
    phone: [values.phoneNumber],
    firstName: [values.firstName],
    lastName: [values.lastName],
  });
  void converge?.track?.lead({
    sourceContentName: "Registration form",
  });
}

export function seekaTrackWaitlistSignup(
  event: { id: string; name: string },
  values: WaitListFormValues,
  ticketTypes: { id: string; name: string; quantity?: number }[]
): void {
  void converge?.identity?.mergeProfile({
    email: [values.email],
    phone: [values.phoneNumber],
    firstName: [values.firstName],
    lastName: [values.lastName],
  });
  for (const ticketType of ticketTypes) {
    void converge?.track?.addToWishlist({
      productIdentifier: event.id,
      productName: event.name,
      variantIdentifier: ticketType.id,
      variantName: ticketType.name,
      quantity: ticketType.quantity,
    });
  }
}
